import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/Layout"
import iconEcommerce from "../assets/svgs/icon-ecommerce.svg"
import iconDigitalMarketing from "../assets/svgs/icon-digitalmarketing.svg"
import iconConsulting from "../assets/svgs/icon-consulting.svg"
import SocialMenu from "../components/SocialMenu"
import Seo from "../components/Seo"

const FrontPage = ({ data }) => {
  const {
    imageJourneyData,
    projectGreenIndustrial,
    projectIndustrialJbaOffroad,
    projectWitsendMosaic,
    page,
  } = data

  const imageJourney = imageJourneyData?.childImageSharp?.fluid

  return (
    <Layout>
      <Seo seoData={page.seo} uri={page.uri} />

      <section id="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h1>
                <span className="yellow">We Are</span>
                <span className="strong">Magento / Commerce / Innovation</span>
              </h1>
              <p>
                We design and develop solutions that allow you to not only find
                but celebrate success.
              </p>
              <div className="d-md-block d-flex align-items-center">
                <Link
                  to="/contact/"
                  className="btn btn-primary btn-cta on-darks"
                >
                  Get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="we-make-it-easy"
        className="seventy-five-vh white-bg d-flex align-items-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-9 offset-3 col-lg-10 offset-lg-1">
              <h2>
                <span
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-bounce"
                >
                  Ecommerce can be complicated, but it doesn't have to be.
                </span>
                <span
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-bounce"
                  className="strong"
                  style={{ fontFamily: "'Proxima Nova', sans-serif" }}
                >
                  WE MAKE IT EASY.
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div
          className="social-icons-right"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-duration="800"
          data-sal-easing="ease-out-bounce"
        >
          <SocialMenu />
        </div>
      </section>
      <BackgroundImage
        Tag="section"
        fluid={imageJourney}
        id="start-your-journey"
        className="seventy-five-vh"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-9 offset-3 col-lg-8 offset-lg-4 mb-5 mb-lg-0 pb-5 pb-lg-0">
              <h2
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                className="mb-5 mb-lg-0 pb-5 pb-lg-0"
              >
                Start your ecommerce journey, and find reasons for us to help.
              </h2>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section id="services" className="pt-4 pb-4">
        <div className="container-fluid">
          <div className="item d-lg-flex align-items-center row">
            <div className="col-lg-6">
              <img
                data-src={iconEcommerce}
                title="Ecommerce Website Development"
                alt="Ecommerce Website Development"
                className="d-block ml-lg-auto mr-lg-0 m-auto pb-3 pr-lg-5 lazy"
              />
            </div>
            <div className="col-lg-6 text-center text-lg-left pl-lg-5">
              <h3 className="strong">Ecommerce Website Development</h3>
              <p>
                Our Magento development team has a proven track record in
                planning, designing, developing and successfully delivering
                technically challenging e-commerce websites using Magento.
              </p>
            </div>
          </div>
          <div className="item d-flex flex-column flex-lg-row align-items-center row">
            <div className="col-lg-6 text-center text-lg-right pr-lg-5">
              <h3 className="strong">Digital Marketing & SEO</h3>
              <p>
                We offer a wide array of digital marketing services across
                multiple digital channels that increase brand awareness,
                increase traffic, and drive sales.
              </p>
            </div>
            <div className="col-lg-6 order-first order-lg-2">
              <img
                data-src={iconDigitalMarketing}
                title="Digital Marketing & SEO"
                alt="Digital Marketing & SEO"
                className="d-block m-auto ml-lg-0 mr-lg-auto pb-3 pl-lg-5 lazy"
              />
            </div>
          </div>
          <div className="item d-lg-flex align-items-center row">
            <div className="col-lg-6 mb-3">
              <img
                data-src={iconConsulting}
                title="Consulting"
                alt="Consulting"
                className="d-block ml-lg-auto mr-lg-0 m-auto pb-3 pr-lg-5 lazy"
              />
            </div>
            <div className="col-lg-6 text-center text-lg-left pl-lg-5">
              <h3 className="strong">Consulting</h3>
              <p>
                We provide services that help businesses tackle the diverse
                commerce challenges that face visionary, transformative
                companies of today.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-bg" id="homepage-blog-cta">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <h2 className="pb-4 text-center text-lg-left">
                For news, tricks and advice on all things commerce.
              </h2>
              <div className="d-lg-block d-flex align-items-center justify-content-center">
                <Link to="/blog/" className="btn btn-primary btn-cta on-darks">
                  Our Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="portfolio-preview">
        <div className="container">
          <div className="row">
            <Link to={projectGreenIndustrial?.uri} className="col-lg-6">
              <BackgroundImage
                preserveStackingContext
                className="port-item"
                data-sal="slide-up"
                data-sal-delay="50"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                alt="Green Industrial Magento 2 Build"
                fluid={
                  projectGreenIndustrial?.featuredImage?.node?.remoteFile
                    ?.childImageSharp?.fluid
                }
              >
                <div className="overlay">
                  <div>
                    <h3 className="strong">Green Industrial</h3>
                    <span>View Project</span>
                  </div>
                </div>
              </BackgroundImage>
            </Link>
          </div>
          <div className="row">
            <Link
              to={projectIndustrialJbaOffroad?.uri}
              className="col-lg-6 offset-lg-6"
            >
              <BackgroundImage
                preserveStackingContext
                className="port-item"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                alt="JBA Offroad Magento 2 Build"
                // data-background-image="{!! wp_get_attachment_url(147) !!}">
                fluid={
                  projectIndustrialJbaOffroad?.featuredImage?.node.remoteFile
                    ?.childImageSharp?.fluid
                }
              >
                <div className="overlay">
                  <div>
                    <h3 className="strong">JBA Offroad</h3>
                    <span>View Project</span>
                  </div>
                </div>
              </BackgroundImage>
            </Link>
          </div>
          <div className="row">
            <Link
              to={projectWitsendMosaic?.uri}
              className="col-lg-8 offset-lg-1"
            >
              <BackgroundImage
                preserveStackingContext
                className="port-item"
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-duration="800"
                data-sal-easing="ease-out-bounce"
                alt="Witsend Mosaic Magento 2 Build Project"
                fluid={
                  projectWitsendMosaic?.featuredImage?.node.remoteFile
                    ?.childImageSharp?.fluid
                }
              >
                <div className="overlay">
                  <div>
                    <h3 className="strong">Witsend Mosaic</h3>
                    <span>View Project</span>
                  </div>
                </div>
              </BackgroundImage>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FrontPage

export const query = graphql`
  query FrontPage($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      uri
      seo {
        ...SeoData
      }
    }
    imageJourneyData: file(name: { eq: "georgie-cobbs-467923-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    projectGreenIndustrial: wpProject(slug: { eq: "green-industrial" }) {
      title
      uri
      featuredImage {
        node {
          remoteFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    projectIndustrialJbaOffroad: wpProject(slug: { eq: "jba-offroad" }) {
      title
      uri
      featuredImage {
        node {
          remoteFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    projectWitsendMosaic: wpProject(slug: { eq: "witsend-mosaic" }) {
      title
      uri
      featuredImage {
        node {
          remoteFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 82) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
